<script setup lang="ts">
import {formatDate, formatTimezone} from "~/utils/date";
import {useTimezone} from "~/composables/useTimezone";

const props = withDefaults(defineProps<{
  date: string | null
  timezone?: string
}>(), {
  timezone: undefined
});

const timezoneFromState = useTimezone();

const timezone = computed(() => {
  return props.timezone ?? timezoneFromState.value;
});

// const currentTimezoneOffset = computed(() => {
//   const now = new Date();
//   const formatter = new Intl.DateTimeFormat('en-US', {
//     timeZoneName: 'short',
//     timeZone: timezone.value
//   });
//   const parts = formatter.formatToParts(now);
//   const timeZonePart = parts.find(part => part.type === 'timeZoneName');
//
//   return timeZonePart ? timeZonePart.value : 'GMT';
// });

const visibleValue = computed(() => formatDate(props.date, {
  timeZone: timezone.value,
  timeStyle: 'medium',
  dateStyle: 'short'
}));

const utcValue = computed(() => {
  const formattedDate = formatDate(props.date, {
    timeZone: 'Etc/UTC',
    timeStyle: 'medium',
    dateStyle: 'short'
  });

  return formattedDate ? `${formattedDate} (${formatTimezone('Etc/UTC')})`: null;
});

const localValue = computed(() => {
  const formattedDate = formatDate(props.date, {
    timeStyle: 'medium',
    dateStyle: 'short'
  });

  return formattedDate ? `${formattedDate} (${formatTimezone()})`: null;
});

const merchantDateTime = computed(() => {
  const formattedDate = formatDate(props.date, {
    timeZone: timezone.value,
    timeStyle: 'medium',
    dateStyle: 'short'
  });

  return formattedDate ? `${formattedDate} (${formatTimezone(timezone.value)})`: null;
});
</script>

<template>
  <div class="date-component">
    <div v-if="visibleValue" class="date-component-value">{{ visibleValue }}</div>
    <UITooltip v-if="visibleValue">
      <template #trigger>
        <span class="date-component-icon-wrapper"><Icon name="lucide:info"/></span>
      </template>
      <template #default>
        <dl class="date-component-tooltip">
          <dt>UTC Time:</dt>
          <dd>{{ utcValue }}</dd>
          <dt>Local time:</dt>
          <dd>{{ localValue }}</dd>
          <dt>Displayed time:</dt>
          <dd>{{ merchantDateTime }}</dd>
        </dl>
      </template>
    </UITooltip>
  </div>
</template>

<style scoped>
.date-component {
  display: flex;
  align-items: center;
  gap: 4px;
}

.date-component-value {
  display: flex;
  align-items: center;
  gap: var(--tw-size-2);
}

.date-component-tooltip {
  text-align: start;
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: var(--tw-size-2);
}

.date-component-icon-wrapper {
  display: inline-flex;
  color: var(--text-secondary-color);
}

.timezone-chip {
  padding: 4px;
  background: var(--bgc-main);
  color: var(--text-secondary-color);
  font-size: var(--tw-font-size-xs);
  border-radius: 4px;
  cursor: default;
}
</style>
